

@mixin animation-spinner-horizontal($duration:3000ms) {
	-webkit-animation-name: spinner-horizontal;
	-ms-animation-name: spinner-horizontal;
	-ms-animation-duration: $duration;
	-webkit-animation-duration: $duration;
    -webkit-animation-iteration-count: infinite;
	-ms-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
	-ms-animation-timing-function: linear;
}

@mixin step-spinner-horizontal($tX, $rt, $scX) {
    -webkit-transform :  translateX($tX) rotate($rt) scaleX($scX);
    -moz-transform :  translateX($tX) rotate($rt) scaleX($scX);
    -ms-transform :  translateX($tX) rotate($rt) scaleX($scX);
    -o-transform :  translateX($tX) rotate($rt) scaleX($scX);
    transform :  translateX($tX) rotate($rt) scaleX($scX);
}

@-moz-keyframes spinner-horizontal {
    0%{
        @include step-spinner-horizontal(-80%, 90deg, 1) 
    }
    33.333% {
        @include step-spinner-horizontal(-80%, -270deg, 1) 
    }
    33.334% {
        @include step-spinner-horizontal(0%, -90deg, -1) 
    }
    50% {
        @include step-spinner-horizontal(0%, 90deg, -1) 
    }
    50.001%{
        @include step-spinner-horizontal(80%, -90deg, 1) 
    }
    83.333% {
        @include step-spinner-horizontal(80%, -450deg, 1) 
    }
    83.334% {
        @include step-spinner-horizontal(0%, 90deg, -1) 
    }
    100% {
        @include step-spinner-horizontal(0%, 270deg, -1) 
    }     
}


@-webkit-keyframes spinner-horizontal {
    0%{
        @include step-spinner-horizontal(-80%, 90deg, 1) 
    }
    33.333% {
        @include step-spinner-horizontal(-80%, -270deg, 1) 
    }
    33.334% {
        @include step-spinner-horizontal(0%, -90deg, -1) 
    }
    50% {
        @include step-spinner-horizontal(0%, 90deg, -1) 
    }
    50.001%{
        @include step-spinner-horizontal(80%, -90deg, 1) 
    }
    83.333% {
        @include step-spinner-horizontal(80%, -450deg, 1) 
    }
    83.334% {
        @include step-spinner-horizontal(0%, 90deg, -1) 
    }
    100% {
        @include step-spinner-horizontal(0%, 270deg, -1) 
    }     
}
@keyframes spinner-horizontal {
    
    0%{
        @include step-spinner-horizontal(-80%, 90deg, 1) 
    }
    33.333% {
        @include step-spinner-horizontal(-80%, -270deg, 1) 
    }
    33.334% {
        @include step-spinner-horizontal(0%, -90deg, -1) 
    }
    50% {
        @include step-spinner-horizontal(0%, 90deg, -1) 
    }
    50.001%{
        @include step-spinner-horizontal(80%, -90deg, 1) 
    }
    83.333% {
        @include step-spinner-horizontal(80%, -450deg, 1) 
    }
    83.334% {
        @include step-spinner-horizontal(0%, 90deg, -1) 
    }
    100% {
        @include step-spinner-horizontal(0%, 270deg, -1) 
    }
}
