.dx-datagrid .dx-datagrid-headers .dx-datagrid-content .dx-datagrid-table .dx-row.dx-header-row > td,
.dx-datagrid .dx-row > td,
.dx-datagrid .dx-pager {
    padding-top: 3px;
    padding-bottom: 3px;

    .dx-page-sizes {
        .dx-texteditor.dx-editor-filled {
            background-color: var(--color1);
            &:after {
                border-bottom: none;
            }
        }
        .dx-texteditor.dx-state-focused {
            &:before {
                border-color: var(--color6);
            }
        }
    }

    .dx-texteditor.dx-editor-filled {
        &:after {
            border-bottom: none;
        }
    }

    .dx-datagrid-pager,
    .dx-pages {
        .dx-texteditor.dx-editor-filled {
            .dx-texteditor-input {
                font-family: var(--common-font);
                color: var(--color6);
                background-color: var(--color1);
                min-width: 34px;
                text-align: center;
                padding: 9px 0px 8px;
            }
            &:after {
                border-bottom: none;
            }
        }
    }

    .dx-texteditor.dx-state-focused {
        &:before {
            border-color: var(--color6);
        }
    }

    .dx-pages .dx-prev-button::before {
        margin-left: 5px;
    }

    .dx-light-pages .dx-info-text {
        padding-left: 2px;
    }
}

.dx-datagrid .dx-datagrid-headers .dx-datagrid-content {
    margin-top: -1px;

    .dx-datagrid-table .dx-row.dx-header-row {
        & > td,
        & > td.dx-command-edit {
            background-color: var(--color6lighten3);
            border-color: var(--color2);
        }
    }
}

.dx-datagrid .dx-row-lines > td {
    border: 1px solid var(--color2);
    .data-error{
        font-style: italic;
        color: var(--color-ko);
        font-size: 11px;
        opacity: 0.7;
    }
    .data-loading{
        font-style: italic;
        color: var(--color6);
    }

}

.dx-datagrid
    .dx-datagrid-content
    .dx-datagrid-table
    .dx-row
    > td.dx-cell-modified:not(.dx-field-item-content):not(.dx-validation-pending).dx-editor-cell:not(.dx-command-select),
.dx-datagrid
    .dx-datagrid-content
    .dx-datagrid-table
    .dx-row
    > td.dx-datagrid-invalid:not(.dx-field-item-content):not(.dx-validation-pending):not(.dx-datagrid-select-all).dx-editor-cell:not(.dx-command-select),
.dx-datagrid
    .dx-datagrid-content
    .dx-datagrid-table
    .dx-row
    > td:not(.dx-validation-pending):not(.dx-datagrid-select-all).dx-editor-cell:not(.dx-command-select) {
    border: solid 1px var(--color2);
}

.dx-datagrid .dx-datagrid-content .dx-datagrid-table {
    .dx-row > td.dx-cell-modified:not(.dx-field-item-content):not(.dx-validation-pending).dx-command-select,
    .dx-row
        > td.dx-datagrid-invalid:not(.dx-field-item-content):not(.dx-validation-pending):not(.dx-datagrid-select-all).dx-command-select,
    .dx-row > td:not(.dx-validation-pending):not(.dx-datagrid-select-all).dx-command-select {
        padding-left: 2px;
    }
}

.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused) > td,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused) > tr > td,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover > td,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover > tr > td {
    background-color: var(--color6);
    color: var(--color1);
}

.dx-datagrid,
.dx-datagrid-column-chooser-list {
    .dx-pager .dx-page-sizes .dx-selection,
    .dx-pager .dx-pages .dx-selection,
    .dx-checkbox-indeterminate .dx-checkbox-icon,
    .dx-checkbox-checked .dx-checkbox-icon {
        background-color: var(--color6lighten);
    }
}

.dx-datagrid .dx-toolbar .dx-toolbar-items-container {
    height: 30px;
}

.dx-datagrid-column-chooser-list {
    .dx-treeview-node .dx-checkbox {
        top: 0px;
    }

    .dx-treeview-item {
        min-height: unset;
        padding: 0px 8px 0px 32px;
        margin-bottom: 4px;
    }

    .dx-treeview-toggle-item-visibility {
        height: 13px;
    }
}

.dx-datagrid-column-chooser {
    .dx-popup-title.dx-toolbar .dx-toolbar-items-container {
        height: 30px;
    }
}

.dx-scrollable-scrollbar.dx-scrollbar-horizontal {
    height: 13px !important;

    .dx-scrollable-scroll {
        //margin-top: 11px !important;
        height: 13px !important;

        .dx-scrollable-scroll-content {
            height: 13px !important;
        }
    }
}
// Added padding to prevent scroll bar going over the last row
.dx-datagrid-rowsview .dx-scrollable-content {
    padding-bottom: 10px;
}

//.container-grid > div.dx-widget[fixedscrollbar="true"][fixedheader="true"]  {
//    div{color: purple !important;}
//}

// Disabled margin creating space after scrollbar
/* div.dx-widget[role="presentation"]:not([fixedscrollbar="true"]) {
    .dx-scrollable-scroll {
        margin-top: 11px !important;
    }

    .dx-scrollable-wrapper {
        margin-bottom: 10px !important;
    }
} */

div.dx-widget[role="presentation"][fixedscrollbar="true"] {
    //    div {color: blue !important;}
    .dx-scrollable-scrollbar.dx-scrollbar-horizontal {
        position: fixed;
        left: auto;
        //    bottom: 0px;
    }
}

div.dx-widget[role="presentation"][fixedheader="true"] {
    //    div{color: red !important;}

    .dx-datagrid-headers {
        position: sticky;
        z-index: 4;
        top: 0px;
    }
}

.dx-datagrid-content .render-boolean-check {
    color: var(--color-ok);
}


