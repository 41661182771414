@import "./../../scss/animation/animation-overlay.scss";

.common-overlay {

    &.overlay-not-transparent {
        // IMPORTANT LE PARENT du .common-overlay DOIT AVOIR UNE POSITION RELATIVE 
        position: absolute;
        height: 100%;
        width: 100%;
        top:0;
        left:0;
    }

    .layer-common-overlay {
        height: 100%;
        width: 100%;
        top:0;
        left:0;
        
        &.overlay-transparent {
            position: fixed ;
        }

        &.overlay-not-transparent {
            position: absolute;
        }
    
        z-index: 100000;
        @include animation-overlay;

        &.dark {
            background-color:var(--color5Opacity);
        }

        &.light {
            background-color:var(--color1Opacity);
        }

        &.transparent {
            background-color:var(--color-transparent);
        }

        &.mainColor {
            background-color: var(--color6lighten2Opacity);
        }
    }

    .content-common-overlay {
        position:absolute;
        display: inline-block;
        top: 1.95rem;
        z-index: 149900;
    }


}

.dropdown-selector .common-overlay .content-common-overlay { 
    max-width: 850px;
}