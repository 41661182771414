@import "../scss/animation/animation-message-loading-infinity.scss";

.component-loading-infinity {

    &.crash-mode .content-common-overlay.overlay-loading-infinity .container-loading-message h3 {
        color: var(--color3);
    }

    &.overlay-transparent {
        position: fixed;

        .close-crash-error {
            font-size: 25px;
            right: 26%;
        }
    }

    &.overlay-not-transparent {
        position: absolute;
        background-color: var(--color1);
    }

    height: 100%;
    width: 100%;
    top:0;
    left:0;

    .content-common-overlay.overlay-loading-infinity {
        &.overlay-transparent {
            position: fixed;

            .container-loading-message {
                border: solid 3px var(--color6lighten);
                border-width: 3px 0;
            }
            &.crash-mode{
                .container-loading-message {
                    border: solid 3px var(--color3);
                    border-width: 3px 0;
                }
            }
        }

        &.overlay-not-transparent {
            position: absolute;

            .container-loading-message {
                background-color: var(--color1);
                padding: 40px;
                min-width: 250px;
                min-height: 150px;

                h3 {
                    font-size: 14px;
                }
            }

            p {
                font-size: 14px;
            }
        }

        height: 100%;
        width: 100%;
        top:0;
        left:0;

        // on met bien le loader au centre
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;

        .container-loading-message {

            position: relative;
            background-color: var(--color1) !important;
            padding: 30px 0;

            h3 {
                transition: ease-in-out color 0.3s;
                color: var(--color6lighten);
                font-size: 30px;

                .moving {

                    $delais : 0.3s;
                    display: inline-block;

                    &.title {
                        @include animation-message-loading($delais*3);

                    }

                    &.point1 {
                        @include animation-message-loading($delais*2);

                    }

                    &.point2 {
                        @include animation-message-loading($delais*1);

                    }

                    &.point3 {
                        @include animation-message-loading($delais*0);
                    }

                }
            }

            p {
                font-size: 18px;
            }
        }
    }

    .close-crash-error {
        position: absolute;
        top: 0;
        right: 9px;
        cursor: pointer;
        color: var(--color3);

        &.picto-container.picto-click-enabled {

            &:hover,
            &:focus {
                color: var(--color3lighten);
            }
        }
    }


}