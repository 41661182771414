@import "../../scss/animation/animation-spinner-infinity.scss";


@mixin spinner-infinity-mixin ($sizeBigCircle ) {

    $sizeLittleCircle : $sizeBigCircle * 6 / 10; 
    $sizeLittleFaceTriangle : (($sizeBigCircle - $sizeLittleCircle)/4); 
    $sizeBigFaceTriangle : ($sizeLittleFaceTriangle * 2.2) ; 


    display: flex;
    flex-flow: row;
    align-content: center;
    justify-content: center;

    &.crash-mode {
        .progress-container {
            display: none;
        }
    }

    .progress-icon{
        background-color: var(--color6lighten2Opacity);
        z-index: 1000;
        width: 0; 
        height: 0; 
        border-top: $sizeLittleFaceTriangle solid transparent;
        border-bottom: $sizeLittleFaceTriangle solid transparent;
        border-left: $sizeLittleFaceTriangle solid white none;
        border-right: $sizeBigFaceTriangle solid white;
        box-shadow: 0px 0px 10px var(--color6lighten2Opacity),
        0px 0px 30px var(--color6lighten2Opacity);

    }

    .progress-container {
        height: $sizeBigCircle;
        width: $sizeBigCircle;
        @include animation-spinner-horizontal();
        flex-flow: row;
        display: flex;
        align-content: center;
        z-index: 1000;
        position: absolute;
        justify-content: center;
    }
  
    &.crash-mode {
        .circle-ext {
            background-color: var(--color3bis);
            &.first-circle {
                background: linear-gradient(90deg, var(--color3) 0%, var(--color3bis) 80%); 
            }
            &.last-circle {
                background: linear-gradient(-90deg, var(--color3) 0%, var(--color3bis) 80%); 
            }
        }
    }

    .circle-ext {
        height: $sizeBigCircle;
        width: $sizeBigCircle;
        border-radius: 50%;
      //  transition: ease-in-out background-color 0.5s;
        background-color: var(--color6lighten2);
        z-index: 999;
        &.first-circle {
            background: linear-gradient(90deg, var(--color6lighten) 0%, var(--color6lighten2) 80%); 
            z-index: 998;

        }
        &.last-circle {
            z-index: 998;
            background: linear-gradient(-90deg, var(--color6lighten) 0%, var(--color6lighten2) 80%); 
        }
        
        
        display: flex;
        justify-content: center;
        flex-flow: wrap;
        align-content: center;

        margin-right: - $sizeLittleFaceTriangle;
        margin-left: - $sizeLittleFaceTriangle;
    }

    .circle-int {
        border-radius: 50%;
        height: $sizeLittleCircle;
        width: $sizeLittleCircle;
        background-color: var(--color1);
        justify-content: center;
        flex-flow: column;
        align-content: center;
        box-shadow: inset 3px 3px 5px rgba(0,0,0,0.1),
        inset -1px -1px 5px rgba(255,255,255,1),;
    }

}


.spinner-infinity:not(.crash-mode) {
    &.xl {
        @include spinner-infinity-mixin (100px);
    }

    &.lg {
        @include spinner-infinity-mixin (80px);
    }

    &.md {
        @include spinner-infinity-mixin (60px);
    }

    &.sm {
        @include spinner-infinity-mixin (40px);
    }

    &.xs {
        @include spinner-infinity-mixin (30px);
    }

    &.xxs {
        @include spinner-infinity-mixin (20px);
    }

    &.xxxs {
        @include spinner-infinity-mixin (10px);
    }
}

