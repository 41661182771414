@mixin foreground-panel {
	background-color: var(--color-background-panel);
	color: var(--color-text-panel);
	font-family: var(--common-font);
	padding: 1.5rem;
	margin: 1rem;
	line-height: 1.2rem;
	font-size: 0.9rem;
	@include border-radius(0.25rem);
	@include box-shadow(0.1rem, 0.2rem, 0.3rem, 0, var(--color-background1-shadow));
}

@mixin foreground-panel-info {
	background-color: var(--color-background-panel-info);
	color: var(--color-text-panel-info);
	font-family: var(--common-font);
	padding: 1.5rem;
	margin: 1rem;
	line-height: 1.2rem;
	font-size: 0.9rem;
	@include border-radius(0.25rem);
	@include box-shadow(0.1rem, 0.2rem, 0.3rem, 0, var(--color-background1-shadow));
}

@mixin common-box-shadow-input-hover-light() {
	@include box-shadow(0, 0, 0, 0, var(--color6lighten3));
	transition: box-shadow 0.15s ease-in-out;
}

@mixin common-box-shadow-input-hover() {
	@include box-shadow(0, 0, 0, 0, var(--color6lighten));
	transition: box-shadow 0.15s ease-in-out;
}

@mixin box-shadow($left, $top, $blur, $spread, $col) {
	box-shadow: $left $top $blur $spread $col;
	-webkit-box-shadow: $left $top $blur $spread $col;
	-moz-box-shadow: $left $top $blur $spread $col;
}

@mixin transition($property, $duration, $easing: linear) {
	transition: $property $duration $easing;
	-webkit-transition: $property $duration $easing;
	-moz-transition: $property $duration $easing;
}

@mixin border-radius($radius) {
	border-radius: $radius;
	-webkit-border-radius: $radius;
	-moz-border-radius: $radius;
}

@mixin border-radius4($r1, $r2, $r3, $r4) {
	border-radius: $r1 $r2 $r3 $r4;
	-webkit-border-radius: $r1 $r2 $r3 $r4;
	-moz-border-radius: $r1 $r2 $r3 $r4;
}

@mixin gradient($col1, $col2) {
	background-color: $col1;
	filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=#{$col1}, endColorstr=#{$col2});
	background-image: -moz-linear-gradient(center top, $col1, $col2);
	background-image: -webkit-gradient(linear, 0% 0%, 0% 100%, from($col1), to($col2));
}

@keyframes blink {
	0% {
		background-color: var(--color-text-hover);
	}
	100% {
		background-color: var(--color-background-panel-elements);
	}
}
@-webkit-keyframes blink {
	0% {
		background-color: var(--color-text-hover);
	}
	100% {
		background-color: var(--color-background-panel-elements);
	}
}

@mixin form-selected-element() {
	font-size: 0.85rem;
	padding: 0.35rem 0.8rem;
	margin: 0.1rem 0.15rem;
	@include border-radius(0.8rem);
	cursor: pointer;
	background-color: var(--color6);
	color: var(--color1);
}

@mixin form-selected-element-toggle() {
	font-size: 0.85rem;
	padding: 0.35rem 0.8rem;
	margin: 0.1rem 0.15rem;
	@include border-radius(0.8rem);
	cursor: pointer;
	background-color: var(--color6);
	color: var(--color1);


}

@mixin form-selected-element-light() {
	@include form-selected-element;
	&::placeholder {
		color: var(--color6lighten2);
	}
}

@mixin form-selected-element-dark() {
	@include form-selected-element;
	//	background :linear-gradient(var(--color1), 95%, var(--color6lighten2Opacity));
	background-color: var(--color1);
	color: var(--color6);
	border-color: var(--color6);
	border-width: 1px;
	border-style: solid;
}

@mixin form-optionnal-element() {
	font-size: 0.75rem;
	padding: 0.1rem 0.4rem 0.2rem 0.55rem;
	margin: 0 0.15rem;
	cursor: pointer;

	&.active {
		cursor: default;
		background-color: var(--color6lighten);
	}
}

@mixin panel-edition($backgroundColor, $borderColor) {
	background-color: $backgroundColor;
	border: solid 1px $borderColor;
	padding: 0.8rem;
	@include border-radius4(0, 0.8rem, 0.8rem, 0.8rem);
	@include box-shadow(0.1rem, 0.2rem, 0.3rem, 0, var(--color2));

	.form-label {
		margin-bottom: 0;
		font-size: 0.85rem;
	}
}

@mixin toggle-icon-cog() {
	.inhovate-custom-toggle {
		.custom-toggle-icon {
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 0.3rem;
			margin: 0.3rem;
			@include border-radius(50%);
		}

		.common-dropdown-menu {
			li > svg {
				vertical-align: top;
			}
		}
	}
}

@mixin accordion() {
	.accordion {
		border: solid 1px var(--color2);
		border-bottom-width: 0px;
	}

	.accordion:last-of-type {
		border-bottom-width: 1px;
	}

	.accordion-button {
		padding: 5px 10px;
		&:not(.collapsed),
		&:hover {
			background-color: var(--color6lighten3);
			color: var(--color6);
		}
		h6 {
			margin: 0;
			font-weight: 600;
			color: var(--color6);
		}
		&::after {
			color: var(--color6);
		}
	}

	.accordion-dimension-footer {
		margin-top: 10px;
	}

	.accordion-dimension-subtitle {
		margin-top: 10px;
	}
}


@mixin buttonRow(){
	
	
		display: flex;
		flex-wrap: wrap;
		flex-shrink: 0;
		align-items: center;
		justify-content: flex-end;
		padding: 0.75rem;
	
	
}

$breakpoints: (
	// BOOTSTRAP Breakpoints
	"sm": 576px,
	"md": 768px,
	"lg": 992px,
	"xl": 1200px,
	"xxl": 1400px
);

@mixin media-min($_key) {
	@media screen and (min-width: map-get($breakpoints, $_key)) {
		& {
			@content;
		}
	}
}

@mixin media-max($_key) {
	@media (max-width: map-get($breakpoints, $_key)) {
		& {
			@content;
		}
	}
}

@mixin remark-my-message(){
	background-color: var(--color6lighten);
	color: white;
    display: flex;
	max-width: 80%;
    margin-left: auto;
}

@mixin remark-other-message(){
	background-color: var(--color-secondary-b);
	color: white;
    display: flex;
}
