@mixin animation-dropdown() {
	-webkit-animation-name: swing-in-top-fwd;
	-ms-animation-name: swing-in-top-fwd;
	-ms-animation-duration: 200ms;
	-webkit-animation-duration: 200ms;
}

/* ----------------------------------------------
 * Generated by Animista on 2021-8-27 10:50:35
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation swing-in-top-fwd
 * ----------------------------------------
 */
 @-webkit-keyframes swing-in-top-fwd {
	0% {
	  -webkit-transform: rotateX(-100deg);
			  transform: rotateX(-100deg);
	  -webkit-transform-origin: top;
			  transform-origin: top;
	  opacity: 0;
	}
	100% {
	  -webkit-transform: rotateX(0deg);
			  transform: rotateX(0deg);
	  -webkit-transform-origin: top;
			  transform-origin: top;
	  opacity: 1;
	}
  }
  @keyframes swing-in-top-fwd {
	0% {
	  -webkit-transform: rotateX(-100deg);
			  transform: rotateX(-100deg);
	  -webkit-transform-origin: top;
			  transform-origin: top;
	  opacity: 0;
	}
	100% {
	  -webkit-transform: rotateX(0deg);
			  transform: rotateX(0deg);
	  -webkit-transform-origin: top;
			  transform-origin: top;
	  opacity: 1;
	}
}