@import "./common-mixins";

.like-a-dataset-tab{
    b{
        background-color: var(--color6);
        display: inline-block;
        padding: 0.1rem 0.2rem 0;
        color: var(--color1);
        font-size: 0.75rem;
        font-weight: 600;
        @include border-radius4(0.4rem,0.4rem,0,0);
    }
    &.variance b {
        background-color: var(--color3);
    }
}