@import "./_common-mixins.scss";

@mixin common-colors-button($background-color, $color) {
    &,
    &:visited {
        background-color: $background-color;
        border-color: $background-color;
        color: $color;
    }
}

@mixin primary-colors {
    @include common-colors-button(var(--color-primary-b), var(--color-button-text));
}

@mixin secondary-colors {
    @include common-colors-button(var(--color-secondary-b), var(--color-button-text));
}

@mixin default-colors {
    @include common-colors-button(var(--color-default-b), var(--color-button-text));
}

@mixin light-colors {
    @include common-colors-button(var(--color-light-b), var(--color-light-f));
}

@mixin success-colors {
    @include common-colors-button(var(--color-ok), var(--color-button-text));
}

@mixin warning-colors {
    @include common-colors-button(var(--color4-light), var(--color-button-text));
}

@mixin danger-colors {
    @include common-colors-button(var(--color4), var(--color-button-text));
}

@mixin common-hover-colors {
    @include common-colors-button(var(--color4-light), var(--color-button-text));
}

@mixin btn-bootstrap-custom {
    padding: 0.3rem 0.5rem 0.1rem;
    font-size: 0.9rem;

    cursor: pointer;
    &:disabled,
    &.disabled {
        cursor: not-allowed;
    }
}

.btn {
    &.btn-primary,
    &.btn-primary:hover {
        @include primary-colors();
        @include btn-bootstrap-custom();
        &:disabled{
            @include primary-colors();
            @include btn-bootstrap-custom();
            opacity: 0.3;
        }
    }

    &.btn-secondary {
        @include secondary-colors();
        @include btn-bootstrap-custom();
        &:disabled{
            @include secondary-colors();
            @include btn-bootstrap-custom();
            opacity: 0.3;
        }
    }

    &.btn-default {
        @include default-colors();
        @include btn-bootstrap-custom();
        &:disabled{
            @include default-colors();
            @include btn-bootstrap-custom();
            opacity: 0.3;
        }
    }

    &.btn-success {
        @include success-colors();
        @include btn-bootstrap-custom();
        &:disabled{
            @include success-colors();
            @include btn-bootstrap-custom();
            opacity: 0.3;
        }
    }

    &.btn-light {
        @include light-colors();
        @include btn-bootstrap-custom();
        &:disabled{
            @include light-colors();
            @include btn-bootstrap-custom();
            opacity: 0.3;
        }
    }

    &.btn-warning,
    &.btn-warning:hover {
        @include warning-colors();
        @include btn-bootstrap-custom();
    }

    &.btn-danger {
        @include danger-colors();
        @include btn-bootstrap-custom();
        &:disabled{
            @include danger-colors();
            @include btn-bootstrap-custom();
            opacity: 0.3;
        }
    }


    &.btn-primary:hover,
    &.btn-secondary:hover,
    &.btn-default:hover,
    &.btn-success:hover,
    &.btn-light:hover,
    &.btn-warning:hover,
    &.btn-danger:hover {
        @include common-hover-colors();
        @include box-shadow(0.1rem, 0.2rem, 0.3rem, 0, var(--color2));
    }
}

