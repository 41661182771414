@import "./common-mixins";
@import "./animation/animation-dropdown.scss";
@import "./animation/animation-dropdown-toggle-icon.scss";

// .dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-row > td.dx-cell-modified:not(.dx-field-item-content):not(.dx-validation-pending).dx-editor-cell:not(.dx-command-select),
// .dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-row > td.dx-datagrid-invalid:not(.dx-field-item-content):not(.dx-validation-pending):not(.dx-datagrid-select-all).dx-editor-cell:not(.dx-command-select),
// .dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-row > td:not(.dx-validation-pending):not(.dx-datagrid-select-all).dx-editor-cell:not(.dx-command-select) {
//     border: solid 2px #f0f;
//     overflow: visible;
// }

.modal {
    border: solid 1px var(--color6);
    overflow-x: auto;
}

.dx-datagrid .dx-datagrid-content .dx-datagrid-table {
    .dx-row.dx-edit-row {
        overflow: visible;
        & > td.dx-editor-cell {
            overflow: visible;
        }
    }
}

.dx-datagrid-headers + .dx-datagrid-rowsview {
    border-top: none;
    overflow: inherit;
}

.dx-datagrid-header-panel .dx-toolbar .dx-datagrid-group-panel .dx-group-panel-item {
    padding: 4px 10px !important;
}

.dropdown-selector .common-overlay .layer-common-overlay {
    visibility: hidden;
}

.dropdown {
    display: block;
    width: 100%;
    position: relative;


    &.dark {
        .custom-toggle-icon {
            color: var(--color6);
        }
    }

    .common-dropdown-toggle {
        display: inline-block;
        position: inherit;
        width: 100%;
    }

    &:hover .item:not(.item-no-selected) {
        .top-nav & {
            @include common-box-shadow-input-hover-light();
        }

        @include common-box-shadow-input-hover();
    }

    .common-dropdown-menu {
        display: inline-block;
        position: relative;
        //    width: 100%;
        height: 100%;
        // @include animation-dropdown;
    }

    .custom-toggle-icon {
        cursor: pointer;
        position: absolute;
        right: 0;
        box-shadow: none;

        &.is-not-collapsed {
            @include animation-dropdown-toggle-icon;
        }
        &.is-collapsed {
            @include animation-dropdown-toggle-icon-bis;
        }
    }

    &.outlined {
        .common-dropdown-toggle {
            @include border-radius(1.4rem);
            border-color: var(--color6);
            border-width: 1px;
            border-style: solid;
            padding: 0.35rem;
            background-color: var(--color1);
            line-height: 8px;
        }
    }

    .dropdown-menu .simple-item-list {
        h5 {
            font-size: 0.85rem;
            color: var(--color4-light);
            padding: 0.2rem 0.7rem;
            margin: 0;
            font-variant: small-caps;
            font-weight: 300;
        }

        .item {
            @include form-selected-element;
            width: 85%;
            display: inline-flex;
            flex-flow: wrap;
            flex-flow: row;
            max-width: fit-content;
        }

        ul {
            margin: 0 0.15rem;
            padding: 0;

            li.dropdown-item {
                position: relative;
                @include form-optionnal-element;
                margin: 0;
                padding: 0.3rem 0.4rem 0.25rem;
                line-height: 12px;
                display: flex;
                > span {
                    display: inline-block;
                    line-height: 10px;
                    text-overflow: ellipsis;
                    width: 200px;
                    white-space: nowrap;
                    overflow: hidden;
                    padding-left: 8px;
                }
                // > .svg-inline--fa{
                //    //vertical-align: middle;
                //    line-height: 0.9rem;
                // }

                &.active {
                    background-color: var(--color6lighten);
                }
                &:active {
                    background-color: transparent;
                    color: var(--color5);
                }
                &:hover {
                    background-color: var(--color2);
                    color: var(--color1);
                }
            }
        }
    }

    .dropdown-item {
        margin: 0;
        font-size: 0.75rem;
        &.active,
        &:active {
            background-color: var(--color6lighten);
        }
    }

    .remove-all {
        svg:hover {
            cursor: pointer;
        }

        svg {
            color: var(--color4-light);
/*             position: absolute;
            bottom: 5px;
            right: 0px;
            color: var(--color1); */
            padding-top: 2px;
        }
    }
}

.dx-popup-normal.dx-popup-draggable {
    .dropdown-menu-title {
        font-size: 12px;
        color: var(--color5Opacity);
        font-style: italic;
        padding: 9px 0 0 8px;
        margin-bottom: 3px;
        white-space: nowrap;
    }
    .dropdown-item {
        cursor: pointer;
        color: var(--color6);
        background-color: var(--color1);
        &:hover {
            background-color: var(--color6);
            color: var(--color1);
        }
        &.active {
            background-color: var(--color2-ultralight);
        }
    }
}
.dropdown-formula{
    .inhovate-custom-toggle{
        background-color: var(--color1)!important;
        border: solid 1px var(--color6)!important;
    }
    .common-dropdown-toggle{
        border-color: var(--color1)!important;
    }
    .custom-toggle-icon{
        color: var(--color6)!important;
    }
}