@import "./../../../../commons/scss/_common-mixins.scss";

.picto-container {

    &.picto-click-enabled {
        cursor: pointer;

        &:hover, &:focus {
            color: var(--color1);
        }

        .fa-trash-alt{
            margin-left: 3px;
        }
    }
    color: var(--color2);
    transition: color 0.3s ease-in-out;
}


.dark .picto-container.picto-click-enabled {
    &:hover, &:focus {
        color: var(--color6);
    }
}