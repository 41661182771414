@mixin animation-overlay() {
	-webkit-animation-name: fade-in;
	-ms-animation-name: fade-in;
	-ms-animation-duration: 300ms;
	-webkit-animation-duration: 300ms;
}

@-webkit-keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes fade-in {
	0% {
	  opacity: 0;
	}
	100% {
	  opacity: 1;
	}
}