@mixin animation-dropdown-toggle-icon() {
	-webkit-animation-name: rotate-in-2-cw;
	-ms-animation-name: rotate-in-2-cw;
	-ms-animation-duration: 300ms;
	-webkit-animation-duration: 300ms;
}

@mixin animation-dropdown-toggle-icon-bis() {
	-webkit-animation-name: rotate-in-2-cw-bis;
	-ms-animation-name: rotate-in-2-cw-bis;
	-ms-animation-duration: 300ms;
	-webkit-animation-duration: 300ms;
}


/* ----------------------------------------------
 * Generated by Animista on 2021-8-27 14:37:49
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation rotate-in-2-cw
 * ----------------------------------------
 */
 @-webkit-keyframes rotate-in-2-cw {
    0% {
      -webkit-transform: rotate(-180deg);
              transform: rotate(-180deg);
      opacity: 1;
    }
    100% {
      -webkit-transform: rotate(0);
              transform: rotate(0);
      opacity: 1;
    }
  }
  @keyframes rotate-in-2-cw {
    0% {
      -webkit-transform: rotate(-180deg);
              transform: rotate(-180deg);
      opacity: 1;
    }
    100% {
      -webkit-transform: rotate(0);
              transform: rotate(0);
      opacity: 1;
    }
}
 
@-webkit-keyframes rotate-in-2-cw-bis {
    0% {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg);
      opacity: 1;
    }
    100% {
      -webkit-transform: rotate(0);
              transform: rotate(0);
      opacity: 1;
    }
  }
  @keyframes rotate-in-2-cw-bis {
    0% {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg);
      opacity: 1;
    }
    100% {
      -webkit-transform: rotate(0);
              transform: rotate(0);
      opacity: 1;
    }
}