@mixin animation-message-loading($delay) {
	-webkit-animation-name: message-loading;
	-ms-animation-name: message-loading;
	-ms-animation-duration: 6000ms;
	-webkit-animation-duration: 6000ms;
    -webkit-animation-iteration-count: infinite;
	-ms-animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease-in-out;
	-ms-animation-timing-function: ease-in-out;
    -webkit-animation-delay: $delay;
	-ms-animation-delay: $delay;
}

@-webkit-keyframes message-loading {
    0%{
    }
    50% {
        opacity:0;
        transform: translateX(100px); 
    }
    60%{
        opacity:0;
        transform: translateX(-100px);
    }
    70% {
        opacity:1;
        transform: translateX(0px); 
    }  
}
@keyframes message-loading {
    
    0%{
        opacity:1;
        transform: translateX(0px);
    }
    60%{
        opacity:1;
        transform: translateX(0px);
    }
    70% {
        opacity:0;
        transform: translateX(100px); 
    }
    90%{
        opacity:0;
        transform: translateX(-100px);
    }
    100% {
        opacity:1;
        transform: translateX(0px); 
    }  
}
